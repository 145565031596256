
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p><a {...{
        "href": "https://ipapi.co",
        "parentName": "p"
      }}>{`IPAPI.co`}</a>
      {` provides IP GeoLocation API with 1k lookups `}
      <a {...{
        "href": "https://ipapi.co/pricing/",
        "parentName": "p"
      }}>{`free quota`}</a>
      {` per day, requiring no sign-up.`}</p>
    <p>{`This API has stricter limitation on burst requests. In case rate-limit is triggered, try to disable parallelization or fasten the rate-limiting options (e.g. `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`3`}</inlineCode>
      {` and `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`2`}</inlineCode>
      {`, respectively).`}</p>
    <p>{`Even though it is fairly accurate, lat/long for some IP ranges are only available for paid plans. As a result, some hops might disappear on the map.`}</p>
  </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;

import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p><a {...{
        "href": "https://ip.sb/api/",
        "parentName": "p"
      }}>{`IP.sb`}</a>
      {` provides free and unlimited IP GeoLocation API, requiring no sign-up.`}</p>
    <p>{`Their data comes from `}
      <a {...{
        "href": "https://www.maxmind.com/",
        "parentName": "p"
      }}>{`MaxMind`}</a>
      {`'s GeoLite2 database ( `}
      <a {...{
        "href": "https://github.com/fcambus/telize",
        "parentName": "p"
      }}>{`telize`}</a>
      {` ), of which the accuracy is fairly low.`}</p>
  </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;

import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p><a {...{
        "href": "https://ipgeolocation.io/",
        "parentName": "p"
      }}>{`IPGeolocation.io`}</a>
      {` provides `}
      <a {...{
        "href": "https://www.bigdatacloud.com/ip-geolocation-apis/ip-address-geolocation-api",
        "parentName": "p"
      }}>{`IP Geolocation API`}</a>
      {` with 1k lookups per day free quota.`}</p>
    <p><a {...{
        "href": "https://ipgeolocation.io/signup.html",
        "parentName": "p"
      }}>{`Sign-up`}</a>
      {` is required for an `}
      <a {...{
        "href": "https://app.ipgeolocation.io/",
        "parentName": "p"
      }}>{`API key`}</a>
      {`.`}</p>
  </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
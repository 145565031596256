
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p><a {...{
        "href": "https://IPInfo.io",
        "parentName": "p"
      }}>{`IPInfo.io`}</a>
      {` is somewhat more accurate than IP.sb which is backed MaxMind's GeoLite2 database.`}</p>
    <p>{`The `}
      <a {...{
        "href": "https://ipinfo.io/account/token",
        "parentName": "p"
      }}>{`API access token`}</a>
      {` is optional, but requests without token are rate-limited on a daily basis. After signing up, their free plan provides with 50k lookups quota per month.`}</p>
  </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
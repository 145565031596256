
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`The JavaScript function is expected to match the following signature:`}</p>
    <pre><code {...{
        "className": "language-ts",
        "parentName": "pre"
      }}>{`(ip: string) => Promise<IPGeo>
`}</code></pre>
    <p>{`where `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`IPGeo`}</inlineCode>
      {` is:`}</p>
    <pre><code {...{
        "className": "language-ts",
        "parentName": "pre"
      }}>{`export interface IPGeo {
    region?: string,
    label?: string, // e.g. org or ISP name
    lat?: number,
    lon?: number,
}
`}</code></pre>
    <p>{`.`}</p>
    <p>{`As the function is executed in the browser runtime, external HTTP resources requested by the function should have proper `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`Access-Control-Allow-Origin`}</inlineCode>
      {` HTTP header set.`}</p>
  </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
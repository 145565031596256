
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p><a {...{
        "href": "https://bigdatacloud.com/",
        "parentName": "p"
      }}>{`BigDataCloud.com`}</a>
      {` provides `}
      <a {...{
        "href": "https://www.bigdatacloud.com/ip-geolocation-apis/ip-address-geolocation-api",
        "parentName": "p"
      }}>{`IP Geolocation API`}</a>
      {` with first 10k lookups per month free of charge.`}</p>
    <p><a {...{
        "href": "https://www.bigdatacloud.com/customer/account/create",
        "parentName": "p"
      }}>{`Sign-up`}</a>
      {` is required for an `}
      <a {...{
        "href": "https://www.bigdatacloud.com/customer/account",
        "parentName": "p"
      }}>{`API key`}</a>
      {`. No IPv6 support.`}</p>
  </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
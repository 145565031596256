
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p><inlineCode {...{
        "parentName": "p"
      }}>{`{IP}`}</inlineCode>
      {` in the URL will substituted to the target IP address when querying.`}</p>
    <p>{`The API is expected to return JSON data matching the following interface:`}</p>
    <pre><code {...{
        "className": "language-ts",
        "parentName": "pre"
      }}>{`export interface IPGeo {
    region?: string,
    label?: string, // e.g. ASN or org name
    lat?: number,
    lon?: number,
}
`}</code></pre>
    <p>{`with `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`Content-Type: application/json`}</inlineCode>
      {` and proper `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`Access-Control-Allow-Origin`}</inlineCode>
      {` HTTP header set.`}</p>
    <p><strong {...{
        "parentName": "p"
      }}>{`Example`}</strong>
      {`: `}
      <a {...{
        "href": "https://github.com/Gowee/traceroute-map-panel/blob/master/ipip-cfworker.js",
        "parentName": "p"
      }}>{`ipip-cfworker.js`}</a></p>
  </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
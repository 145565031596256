
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p><a {...{
        "href": "https://ipdata.co/",
        "parentName": "p"
      }}>{`IPData.co`}</a>
      {` provides a free plan with 1.5k lookups per day quota for non-commercial use.`}</p>
    <p><a {...{
        "href": "https://dashboard.ipdata.co/sign-up.html",
        "parentName": "p"
      }}>{`Sign-up`}</a>
      {` is required for an `}
      <a {...{
        "href": "https://dashboard.ipdata.co/",
        "parentName": "p"
      }}>{`API key`}</a>
      {`.`}</p>
  </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;